<template>
  <div id="app" class="is-flex is-flex-direction-column is-justify-content-space-between">
    <div v-if="!is_mobile" class="box feedback-box" :class="{
      'feedback-form-active': show_feedback_modal && !minimize_feedback_modal,
      'feedback-form-hidden':
        !show_feedback_modal && !minimize_feedback_modal,
      'feedback-form-minimized': minimize_feedback_modal,
    }">
      <FeedbackForm @toggle_modal="minimize_feedback_modal = !minimize_feedback_modal" @close_modal="
        minimize_feedback_modal = false;
      show_feedback_modal = false;
      "></FeedbackForm>
    </div>
    <NavBar />
    <header v-if="show_header">
      <Header :page_slug="current_page_slug" :title="custom_title" @loaded="isHeaderLoaded = true"></Header>
    </header>
    <div id="app-router-view-wrapper" class="expand" v-show="isLoaded">
      <router-view @hide_header="
        show_header = false;
      isHeaderLoaded = true;
      " @update_title="set_new_title($event)" @loaded="isContentLoaded = true" />
    </div>
    <div v-if="!isLoaded">
      <Loading />
    </div>
    <footer>
      <Footer></Footer>
    </footer>
  </div>
</template>

<script>
// import NavBar from "@/components/NavBar";
// import Footer from "@/components/Footer";
// import Header from "@/components/Header";
// import FeedbackForm from "@/components/FeedbackForm";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    NavBar: () => import("@/components/NavBar.vue"),
    Footer: () => import("@/components/Footer.vue"),
    Header: () => import("@/components/Header.vue"),
    FeedbackForm: () => import("@/components/FeedbackForm"),
    Loading: () => import("@/components/Loading"),
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      titleTemplate: "%s | Methodenset Gender und Beruf",
      meta: this.$route.meta.meta,
      htmlAttrs: {
        lang: "de",
        amp: true,
      },
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.current_page_slug = route.meta.slug || "";
        this.show_header = true;
        this.custom_title = "";
        this.isContentLoaded = false;
        this.isHeaderLoaded = false;
      },
    },
  },
  data() {
    return {
      showCookieBanner: false,
      current_page_slug: "",
      show_header: true,
      custom_title: "",
      show_feedback_modal: false,
      minimize_feedback_modal: false,
      isHeaderLoaded: false,
      isContentLoaded: false,
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.$store.commit("set_is_mobile", window.innerWidth < 769);
    },
    set_new_title(e) {
      this.custom_title = e;
    },
    minimizeFeedbackModal() {
      this.minimize_feedback_modal = true;
    },
  },
  computed: {
    ...mapState({
      cartItems: (state) => state.cartContent,
      is_mobile: (state) => state.is_mobile,
    }),
    isLoaded() {
      return true;
      // return this.isHeaderLoaded && this.isContentLoaded;
    },
  },
  created() {
    this.current_page_slug = this.$route.meta.slug || "";

    let feedBackShowDelay = 120 * 1000;
    // let feedBackShowDelay = 5 * 1000;

    document.documentElement.lang = "de";

    setTimeout(() => {
      this.show_feedback_modal = true;
    }, feedBackShowDelay);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";

html {
  @media only screen and (min-width: 1408px) {
    font-size: 17px !important;
  }

  @media only screen and (min-width: 1216px) and (max-width: 1407px) {
    font-size: 16px !important;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1215px) {
    font-size: 15px !important;
  }

  @media only screen and (min-width: 769px) and (max-width: 1023px) {
    font-size: 14px !important;
  }

  @media only screen and (min-width: 301px) and (max-width: 768px) {
    font-size: 13px !important;
  }
}

#app {
  font-family: $globalfont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  background: $white;
  overflow: visible;
  min-height: 100vh;

  hyphens: auto;

  @media only screen and (min-width: 1408px) {
    .fokus-group-circle {
      margin-top: 8.5rem;
      margin-right: 3rem;
      transform: scale(0.9, 0.9);
    }
  }

  @media only screen and (min-width: 1216px) and (max-width: 1407px) {
    .fokus-group-circle {
      margin-top: 8rem;
      margin-right: 3rem;
      transform: scale(0.75, 0.75);
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1215px) {
    .fokus-group-circle {
      margin-top: 8rem;
      margin-right: 3rem;
      transform: scale(0.8, 0.8);
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 1023px) {
    .fokus-group-circle {
      margin-top: 6.5rem;
      margin-right: 2rem;
      transform: scale(0.8, 0.8);
    }
  }
}

@media only screen and (max-width: 300px) {
  #app {
    overflow: hidden;
  }
}

.cookie-banner {
  background: $primary;
  position: fixed;
  bottom: 0;
  z-index: 99999999;
  width: 100%;
  min-height: 10rem;
  box-shadow: 1px -15px 29px 2px rgba(0, 0, 0, 0.48);
  -webkit-box-shadow: 1px -15px 29px 2px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 1px -15px 29px 2px rgba(0, 0, 0, 0.48);
}

.feedback-box {
  position: fixed;
  transform-origin: bottom right;
  z-index: 50;
  transition: 300ms;
  padding: 0;
  margin: 1rem !important;
  max-width: 500px;
  width: 40%;
  right: 0;
  bottom: 0;
  height: 0;
}

.feedback-form-minimized {
  bottom: -600px;
}

.feedback-form-active {
  height: auto;
}

.feedback-form-hidden {
  transition: 300ms;
  height: 0;
  margin-bottom: 0 !important;
}
</style>
